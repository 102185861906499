



































































































































































































































































































































































































































































































































.human-resources-matrix-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  td {
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-collapse: collapse;
  }

  .text-vertical{
    //writing-mode: sideways-lr;
  }

  &-evaluation-name-cell {
    font-weight: bold;
    background-color: #145969;
    color: white
  }
  &-intersection-name-cell {
    font-weight: bold;
    background-color: #DEDEDE;
  }
  &-top-cell {
    background-color: #C3E7ED;
  }
  &-cards-cell {
    text-align: left;
  }

}
